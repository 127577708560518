// ----------------------------------------------------------------------

const account = {
	displayName: "John Doe",
	email: "johndoe@jupiter.com",
	photoURL: "/assets/images/avatars/avatar_default.png",
	role: "HR Manager",
};

export default account;
