// @mui
import PropTypes from "prop-types";
import {
	Box,
	Stack,
	Link,
	Card,
	Button,
	Divider,
	Typography,
	CardHeader,
} from "@mui/material";
// utils
import { fToNow } from "../../../utils/formatTime";
// components
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";

import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";

// ----------------------------------------------------------------------

AppNotifications.propTypes = {
	title: PropTypes.string,
	subheader: PropTypes.string,
	list: PropTypes.array.isRequired,
};

export default function AppNotifications({ title, subheader, list, ...other }) {
	return (
		<Card {...other}>
			<CardHeader title={title} subheader={subheader} />

			<Scrollbar>
				{list.length === 0 && (
					<Stack
						spacing={1}
						direction="column"
						alignItems="center"
						sx={{ p: 3, pr: 0 }}
					>
						<NotificationsOffIcon sx={{ fontSize: 50, mt: 2 }} />
						<Typography variant="h7" align="center" sx={{ p: 3, pt: 0 }}>
							No new notifications!
						</Typography>
					</Stack>
				)}

				{list.length > 0 && (
					<Stack spacing={3} sx={{ p: 5, pr: 0 }}>
						{list.map((news) => (
							<NewsItem key={news.id} news={news} />
						))}
					</Stack>
				)}
			</Scrollbar>

			<Divider />

			<Box sx={{ p: 2, textAlign: "right" }}>
				<Button
					size="small"
					color="inherit"
					endIcon={<Iconify icon={"eva:arrow-ios-forward-fill"} />}
				>
					View all
				</Button>
			</Box>
		</Card>
	);
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
	news: PropTypes.shape({
		description: PropTypes.string,
		image: PropTypes.string,
		postedAt: PropTypes.instanceOf(Date),
		title: PropTypes.string,
	}),
};

function NewsItem({ news }) {
	const { image, title, description, postedAt } = news;

	return (
		<Stack direction="row" alignItems="center" spacing={2}>
			<Box
				component="img"
				alt={title}
				src={image}
				sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }}
			/>

			<Box sx={{ minWidth: 240, flexGrow: 1 }}>
				<Link color="inherit" variant="subtitle2" underline="hover" noWrap>
					{title}
				</Link>

				<Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
					{description}
				</Typography>
			</Box>

			<Typography
				variant="caption"
				sx={{ pr: 3, flexShrink: 0, color: "text.secondary" }}
			>
				{fToNow(postedAt)}
			</Typography>
		</Stack>
	);
}
